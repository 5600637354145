<div class="privacy-policy-container" id="start">
  <h1>{{ 'PRIVACY_POLICY.HEADLINE' | translate }}</h1>

  <h2>{{ 'PRIVACY_POLICY.RESPONSIBLE.TITLE' | translate }}</h2>
  <p>
    {{ 'PRIVACY_POLICY.RESPONSIBLE.DESCRIPTION' | translate }}
  </p>

  <h2>{{ 'PRIVACY_POLICY.DATA_COLLECTION.TITLE' | translate }}</h2>

  <h3>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.TITLE' | translate }}</h3>
  <p>
    {{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.DESCRIPTION' | translate }}
  </p>
  <ul>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.ITEMS.0' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.ITEMS.1' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.ITEMS.2' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.ITEMS.3' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.ITEMS.4' | translate }}</li>
  </ul>
  <p>
    {{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.PURPOSE' | translate }}
  </p>
  <ul>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.PURPOSE_ITEMS.0' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.PURPOSE_ITEMS.1' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.PURPOSE_ITEMS.2' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.PURPOSE_ITEMS.3' | translate }}</li>
  </ul>
  <p>
    {{ 'PRIVACY_POLICY.DATA_COLLECTION.VISIT.LEGAL_BASIS' | translate }}
  </p>

  <h3>{{ 'PRIVACY_POLICY.DATA_COLLECTION.CONTACT_FORM.TITLE' | translate }}</h3>
  <p>
    {{ 'PRIVACY_POLICY.DATA_COLLECTION.CONTACT_FORM.DESCRIPTION' | translate }}
  </p>
  <p>
    {{ 'PRIVACY_POLICY.DATA_COLLECTION.CONTACT_FORM.LEGAL_BASIS' | translate }}
  </p>
  <p>
    {{ 'PRIVACY_POLICY.DATA_COLLECTION.CONTACT_FORM.DATA_DELETION' | translate }}
  </p>

  <h2>{{ 'PRIVACY_POLICY.DATA_TRANSFER.TITLE' | translate }}</h2>
  <p>
    {{ 'PRIVACY_POLICY.DATA_TRANSFER.DESCRIPTION' | translate }}
  </p>
  <ul>
    <li>{{ 'PRIVACY_POLICY.DATA_TRANSFER.ITEMS.0' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_TRANSFER.ITEMS.1' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_TRANSFER.ITEMS.2' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.DATA_TRANSFER.ITEMS.3' | translate }}</li>
  </ul>

  <h2>{{ 'PRIVACY_POLICY.RIGHTS.TITLE' | translate }}</h2>
  <p>
    {{ 'PRIVACY_POLICY.RIGHTS.DESCRIPTION' | translate }}
  </p>
  <ul>
    <li>{{ 'PRIVACY_POLICY.RIGHTS.ITEMS.0' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.RIGHTS.ITEMS.1' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.RIGHTS.ITEMS.2' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.RIGHTS.ITEMS.3' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.RIGHTS.ITEMS.4' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.RIGHTS.ITEMS.5' | translate }}</li>
    <li>{{ 'PRIVACY_POLICY.RIGHTS.ITEMS.6' | translate }}</li>
  </ul>

  <h2>{{ 'PRIVACY_POLICY.OBJECTION.TITLE' | translate }}</h2>
  <p>
    {{ 'PRIVACY_POLICY.OBJECTION.DESCRIPTION' | translate }}
  </p>
  <p>
    {{ 'PRIVACY_POLICY.OBJECTION.CONTACT' | translate }}
  </p>

  <h2>{{ 'PRIVACY_POLICY.SECURITY.TITLE' | translate }}</h2>
  <p>
    {{ 'PRIVACY_POLICY.SECURITY.DESCRIPTION' | translate }}
  </p>
  <p>
    {{ 'PRIVACY_POLICY.SECURITY.TECH_MEASURES' | translate }}
  </p>

  <h2>{{ 'PRIVACY_POLICY.CHANGES.TITLE' | translate }}</h2>
  <p>
    {{ 'PRIVACY_POLICY.CHANGES.DESCRIPTION' | translate }}
  </p>
</div>
