<div class="flex-wrapper">
  <div class="content-container" id="contact">
    <div class="headline-container">
      <div class="line-wrapper">
        <div class="deco-line"></div>
      </div>
      <div class="headline">{{ "CONTACT.HEADLINE" | translate }}</div>
    </div>

    <div class="contact-container">
      <div class="introduction">
        <div [@slideInFromBottom]="slideInBottom" class="introduction-headline">
          <b>{{ "CONTACT.INTRODUCTION" | translate }}</b>
        </div>
        <p>{{ "CONTACT.DESCRIPTION_1" | translate }}</p>
        <p>
          {{ "CONTACT.DESCRIPTION_2" | translate }}
          <b>{{ "CONTACT.DESCRIPTION_3" | translate }}</b>
        </p>
      </div>
      <div class="form-container">
        <form
          [@slideInOutRight]="slideInRight"
          (ngSubmit)="onSubmit(contactForm)"
          #contactForm="ngForm"
        >
          <div class="form-group">
            <input
              #name="ngModel"
              [(ngModel)]="contactData.name"
              type="text"
              id="name"
              placeholder="{{ 'CONTACT.NAME_PLACEHOLDER' | translate }}"
              name="name"
              required
              [ngClass]="{
                'is-invalid': name.invalid && (name.dirty || name.touched)
              }"
            />
            @if (name.invalid && name.touched) {
            <span class="form-alert">{{
              "CONTACT.NAME_ERROR" | translate
            }}</span>
            }
          </div>
          <div class="form-group">
            <input
              #email="ngModel"
              [(ngModel)]="contactData.email"
              type="email"
              id="email"
              placeholder="{{ 'CONTACT.EMAIL_PLACEHOLDER' | translate }}"
              name="email"
              required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              [ngClass]="{
                'is-invalid': email.invalid && (email.dirty || email.touched)
              }"
            />
            @if (email.invalid && email.touched) {
            <span class="form-alert">{{
              "CONTACT.EMAIL_ERROR" | translate
            }}</span>
            }
          </div>
          <div class="form-group">
            <textarea
              #message="ngModel"
              minlength="4"
              [(ngModel)]="contactData.message"
              id="message"
              placeholder="{{ 'CONTACT.MESSAGE_PLACEHOLDER' | translate }}"
              name="message"
              rows="4"
              required
              [ngClass]="{
                'is-invalid':
                  message.invalid && (message.dirty || message.touched)
              }"
            ></textarea>
            @if (!message.valid && message.touched && !message.disabled) {
            <span class="form-alert">{{
              "CONTACT.MESSAGE_ERROR" | translate
            }}</span>
            }
          </div>
          <div class="form-group policy-container">
            <label class="container">
              <input
                #privacyPolicy="ngModel"
                type="checkbox"
                name="privacyPolicy"
                [(ngModel)]="contactData.privacyPolicy"
                required
                [ngClass]="{
                  'is-invalid':
                    privacyPolicy.invalid &&
                    (privacyPolicy.dirty || privacyPolicy.touched)
                }"
              />
              <span class="checkmark"></span>
              <p>
                {{ "CONTACT.PRIVACY_POLICY_TEXT1" | translate }}
                <a routerLink="/Privacy-Policy">{{
                  "CONTACT.PRIVACY_POLICY_TEXT2" | translate
                }}</a>
                {{ "CONTACT.PRIVACY_POLICY_TEXT3" | translate }}
              </p>
            </label>
            @if (privacyPolicy.invalid && (privacyPolicy.dirty ||
            privacyPolicy.touched)) {
            <span class="form-alert">{{
              "CONTACT.PRIVACY_POLICY_ERROR" | translate
            }}</span>
            }
          </div>
          <div class="form-group form-button-container">
            <button
              class="form-button"
              type="submit"
              [disabled]="contactForm.invalid"
            >
              {{ "CONTACT.SEND_BUTTON" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>

    <a href="#hero">
      <svg
        class="go-to-start-button"
        viewBox="0 0 32 32"
        version="1.1"
        fill="#ffffff"
        stroke="#ffffff"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        />

        <g id="SVGRepo_iconCarrier">
          <title>arrow-up-circle</title>
          <desc>Created with Sketch Beta.</desc>
          <defs></defs>
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            sketch:type="MSPage"
          >
            <g
              id="Icon-Set"
              sketch:type="MSLayerGroup"
              transform="translate(-360.000000, -1087.000000)"
              fill="#ffffff"
            >
              <path
                d="M376,1117 C368.268,1117 362,1110.73 362,1103 C362,1095.27 368.268,1089 376,1089 C383.732,1089 390,1095.27 390,1103 C390,1110.73 383.732,1117 376,1117 L376,1117 Z M376,1087 C367.163,1087 360,1094.16 360,1103 C360,1111.84 367.163,1119 376,1119 C384.837,1119 392,1111.84 392,1103 C392,1094.16 384.837,1087 376,1087 L376,1087 Z M376.879,1096.46 C376.639,1096.22 376.311,1096.15 376,1096.21 C375.689,1096.15 375.361,1096.22 375.121,1096.46 L369.465,1102.12 C369.074,1102.51 369.074,1103.14 369.465,1103.54 C369.854,1103.93 370.488,1103.93 370.879,1103.54 L375,1099.41 L375,1110 C375,1110.55 375.447,1111 376,1111 C376.553,1111 377,1110.55 377,1110 L377,1099.41 L381.121,1103.54 C381.512,1103.93 382.145,1103.93 382.535,1103.54 C382.926,1103.14 382.926,1102.51 382.535,1102.12 L376.879,1096.46 L376.879,1096.46 Z"
                id="arrow-up-circle"
                sketch:type="MSShapeGroup"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </a>
    <div class="email-alert d-none" #emailAlert>
      <div class="email-alert-container">
        <div class="animation">
          <div class="i-mail">
            <div class="mail-anim"></div>
          </div>
          <div class="line"></div>
          <div class="i-success">
            <div class="success-anim"></div>
          </div>
        </div>
        <div class="message">{{ "CONTACT.SUCCESS_MESSAGE" | translate }}</div>
      </div>
    </div>
  </div>
  <img class="shadow-purple" src="assets/img/shadow-purple-big2.png" alt="" />
</div>
