<div class="content-container" [@slideInOutLeft]="slideIn">
  <img [src]="projectImage" alt="Project Image" />
  <div class="introduction-container">
    <div class="introduction-headline">
      {{ headlineKey | translate }}
    </div>
    <div class="tech-stack">
      {{ techStackKey | translate }}
    </div>
    <p class="introduction">
      {{ descriptionKey | translate }}
    </p>
    <div class="button-container">
      <div class="test-button">
        <a [href]="liveTestLink" target="_blank">{{ 'PORTFOLIO.LIVE_TEST' | translate }}</a>
      </div>
      <div class="git-button">
        <a [href]="gitLink" target="_blank">{{ 'PORTFOLIO.GITHUB' | translate }}</a>
      </div>
    </div>
  </div>
</div>
