<div class="imprint-container">
  <h1>{{ "IMPRINT.HEADLINE" | translate }}</h1>

  <p>
    Lucas Liebert<br />
    Eichenallee 2g<br />
    16548 Glienicke/Nordbahn
  </p>

  <h2>{{ "IMPRINT.CONTACT" | translate }}</h2>
  <p> 
    <span>{{ "IMPRINT.TELEPHONE" | translate }}:</span> 01732559286<br />
    <span>{{ "IMPRINT.EMAIL" | translate }}:</span> lucas.liebert20&#64;gmail.com
  </p>
</div>
